<template>
  <div>
    <c-search-box @enter="getList">
      <template slot="search">
        <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
          <c-plant type="search" name="plantCd" v-model="searchParam.plantCd" />
        </div>
        <div class="col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2">
          <c-dept
            type="search"
            label="작성부서"
            name="deptCd"
            v-model="searchParam.deptCd">
          </c-dept>
        </div>
      </template>
    </c-search-box>
    <c-table
      ref="table"
      title="비상 시나리오 목록"
      :columns="grid.columns"
      :data="grid.data"
      selection="single"
      rowKey="trainingScenarioId"
      @linkClick="linkClick"
      @rowDblclick="rowDblclick"
    >
      <!-- 버튼 영역 -->
      <template slot="table-button">
        <q-btn-group outline >
          <c-btn label="LBLSEARCH" icon="search" @btnClicked="getList" />
          <c-btn label="LBLSELECT" icon="check" @btnClicked="select" />
        </q-btn-group>
      </template>
    </c-table>
    <c-dialog :param="popupOptions"></c-dialog>
  </div>
</template>

<script>
import selectConfig from '@/js/selectConfig';
export default {
  name: 'emergenct-action-plan-docu-pop',
  props: {
    popupParam: {
      type: Object,
      default: function() {
        return {
          type: 'multiple',
          trainingScenarioId: '',
          deptCd: '',
        }
      },
    },
  },
  data() {
    return {
      grid: {
        columns: [
        {
            name: 'plantName',
            field: 'plantName',
            label: 'LBLPLANT',
            align: 'center',
            sortable: false,
            style: 'width:90px',
          },
          {
            name: 'deptName',
            field: 'deptName',
            label: '부서',
            align: 'center',
            sortable: false,
            style: 'width:90px',
          },
          {
            name: 'trainingScenarioName',
            field: 'trainingScenarioName',
            label: '시나리오명',
            align: 'left',
            type: "link",
            sortable: false,
            style: 'width:400px',
          },
        ],
        data: [],
      },
      searchParam: {
        plantCd: null,
        emergencyTypeCd: null,
        useFlag: 'Y',
      },
      useFlagItems: [
        { code: 'Y', codeName: '사용' },
        { code: 'N', codeName: '미사용' },
      ],
      searchUrl: '',
      editable: true,
      popupOptions: {
        isFull: true,
        target: null,
        title: '',
        visible: false,
        top: '',
        param: {},
        closeCallback: null,
      },
      listUrl: '',
    };
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
    Object.assign(this.$data, this.$options.data());
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  methods: {
    init() {
      // role setting
      this.listUrl = selectConfig.sop.eap.emergTraining.list.url;
      // url setting
      // code setting
      this.searchParam.deptCd = this.popupParam.deptCd
      // list setting
      this.getList();
    },
    getList() {
      this.$http.url = this.listUrl;
      this.$http.type = 'GET';
      this.$http.param = this.searchParam;
      this.$http.request((_result) => {
        this.grid.data = _result.data;
      },);
    },
    
    select() {
      let selectData = this.$refs['table'].selected;
      if (!selectData || selectData.length === 0) {
        window.getApp.$emit('ALERT', {
          title: 'LBLGUIDE', // 안내
          message: '비상조치 시나리오를 선택하세요.', 
          type: 'warning', // success / info / warning / error
        });
      } else {
        this.$emit('closePopup', selectData);
      }
    },
    rowDblclick(row) {
      if (this.popupParam.type === 'single') {
        this.$emit('closePopup', [row]);
      }
    },
    /* eslint-disable no-unused-vars */
    linkClick(row, col, index) {
      // 비상조치계획서 상세 팝업
      this.popupOptions.title = '비상조치 시나리오 상세';
      this.popupOptions.param = {
        trainingScenarioId: row ? row.trainingScenarioId : '',
      };
      this.popupOptions.target = () => import(`${'./emergencyScenarioEvalDetail.vue'}`);
      this.popupOptions.visible = true;
      this.popupOptions.closeCallback = this.closePopup;
    },
    closePopup() {
      this.popupOptions.target = null;
      this.popupOptions.visible = false;
      this.getList();
    },
  }
};
</script>
